<template>
  <div class="about">
    <p>elect v. {{ title }}</p>
    <div v-html="txt"/>
  </div>
</template>
<script>
import axios from 'axios'
import { hostus } from "../utils/func.js";

export default {
  name: 'About',
  components: {
  },
  mounted: async function() {
    let res = await axios.get(`${hostus}/mysqlus/proto`, {params: {file: `infopubl-2` }})
    let proto = res.data
    if (proto.errmsg) this.txt = " Не найден файл" 
    else this.txt = proto
  },
  data() {
    return {
      txt: '',
      title: (process.env.VUE_APP_TITLE).split(' ')[2]
    }
  },
}
</script>
